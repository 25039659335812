import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchServices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/services', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchServiceCategoryList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/service/category/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchExchangeRate(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/setting/exchange-rate')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchService(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/service/${id}/show`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addService(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/service/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateService(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/service/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteService(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/service/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
