<template>
  <!--  <b-card>-->
  <!--    <b-card-title>-->
  <!--      Crear producto-->
  <!--    </b-card-title>-->
  <!-- BODY -->
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <!-- Form -->
    <b-form
      class=""
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-card>
        <b-card-title>
          Crear servicio
        </b-card-title>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <!-- Service Name -->
            <validation-provider
              #default="validationContext"
              name="Nombre del servicio"
              rules="required"
            >
              <b-form-group
                label="Nombre del servicio"
                label-for="product_name"
              >
                <b-form-input
                  id="product_name"
                  v-model="productObject.name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Service Icon -->
            <validation-provider
              #default="validationContext"
              name="Icono del servicio"
            >
              <b-form-group
                label="Icono del servicio"
                label-for="product_code"
              >
                <b-form-input
                  id="product_code"
                  v-model="productObject.icon"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Category -->
            <validation-provider
              #default="validationContext"
              name="Categoria"
              rules="required"
            >
              <b-form-group
                label="Categoria"
                label-for="product_category"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="productObject.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoryListOptions"
                  :clearable="false"
                  label="name"
                  :reduce="val => val.id"
                  input-id="product_category"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <!-- Status -->
            <validation-provider
              #default="validationContext"
              name="Estado"
              rules="required"
            >
              <b-form-group
                label="Estado"
                label-for="product_status"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="productObject.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusesList"
                  :clearable="false"
                  label="name"
                  :reduce="val => val.id"
                  input-id="product_status"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <!-- Price PEN -->
            <validation-provider
              #default="validationContext"
              name="Precio soles"
              rules="required"
            >
              <b-form-group
                label="Precio soles"
                label-for="price_pen"
              >
                <b-form-input
                  id="price_pen"
                  v-model.number="productObject.pricePEN"
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  step="0.000001"
                  @keyup="pricePENListen"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <!-- Price USD -->
            <b-form-group
              label="Precio dólares"
              label-for="price_usd"
            >
              <b-form-input
                id="price_usd"
                v-model="productObject.priceUSD"
                type="number"
                trim
                step="0.000001"
                @keyup="priceUSDListen"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <!-- % Discount -->
            <b-form-group
              label="% Descuento"
              label-for="percentage_discount"
            >
              <b-form-input
                id="percentage_discount"
                v-model="productObject.discount"
                type="number"
                trim
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <!-- Description quotation -->
            <validation-provider
              #default="validationContext"
              name="Descripción en la Cotización"
            >
              <b-form-group
                label="Descripción en la Cotización"
                label-for="description_in_quotation"
              >
                <quill-editor
                  id="description_in_quotation"
                  v-model="productObject.descriptionInQuotation"
                  :state="getValidationState(validationContext)"
                  :options="snowOption"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <!-- Description web -->
            <validation-provider
              #default="validationContext"
              name="Información de la Web"
            >
              <b-form-group
                label="Información de la Web"
                label-for="product_information"
              >
                <quill-editor
                  id="product_information"
                  v-model="productObject.descriptionWeb"
                  :state="getValidationState(validationContext)"
                  :options="snowOption"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
      </b-card>

      <b-card
        v-for="(block,index) in productObject.blocks"
        :key="index"
      >
        <b-card-title>
          Bloque #{{ index + 1 }}
        </b-card-title>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Title block #1 -->
            <validation-provider
              #default="validationContext"
              :name="`Título ${index+1}`"
              rules="required"
            >
              <b-form-group
                :label="`Título ${index+1}`"
                :label-for="`block_title_${index+1}`"
              >
                <b-form-input
                  :id="`block_title_${index+1}`"
                  v-model="block.title"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="`Imagen ${index+1}`"
              rules="required"
            >
              <b-form-group
                :label="`Imagen ${index+1}`"
                :label-for="`image_block_${index+1}`"
              >
                <b-form-file
                  :id="`image_block_${index+1}`"
                  v-model="block.image"
                  :state="getValidationState(validationContext)"
                  placeholder="Subir imagen ( 800x1000px )"
                  drop-placeholder="Drop file here..."
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <div class="" />
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              :name="`Descripción breve ${index+1}`"
              rules="required"
            >
              <b-form-group
                :label="`Descripción breve ${index+1}`"
                :label-for="`content_block_${index+1}`"
              >
                <quill-editor
                  :id="`content_block_${index+1}`"
                  v-model="block.content"
                  :state="getValidationState(validationContext)"
                  :options="snowOption"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <div class="my-1">
          <h3>Secciones:</h3>
        </div>
        <b-row>
          <b-col
            v-for="(step,indexStep) in block.steps"
            :key="index+indexStep"
            cols="12"
            md="6"
          >
            <h5>Número {{ indexStep+1 }}</h5>
            <!-- Title -->
            <validation-provider
              #default="validationContext"
              :name="`Titulo ${indexStep+1} bloque ${index+1}`"
              rules="required"
            >
              <b-form-group
                :label="`Titulo ${indexStep+1} bloque ${index+1}`"
                :label-for="`step_title_${indexStep+1}_block_${index+1}`"
              >
                <b-form-input
                  :id="`step_title_${indexStep+1}_block_${index+1}`"
                  v-model="step.title"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Icon -->
            <validation-provider
              #default="validationContext"
              :name="`Icono ${indexStep+1} bloque ${index+1}`"
              rules="required"
            >
              <b-form-group
                :label="`Icono ${indexStep+1} bloque ${index+1}`"
                :label-for="`step_icon_${indexStep+1}_block_${index+1}`"
              >
                <b-form-input
                  :id="`step_icon_${indexStep+1}_block_${index+1}`"
                  v-model="step.icon"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Content -->
            <validation-provider
              #default="validationContext"
              :name="`Contenido ${indexStep+1} bloque ${index+1}`"
              rules="required"
            >
              <b-form-group
                :label="`Contenido ${indexStep+1} bloque ${index+1}`"
                :label-for="`step_content_${indexStep+1}_block_${index+1}`"
              >
                <quill-editor
                  :id="`step_content_${indexStep+1}_block_${index+1}`"
                  v-model="step.content"
                  :state="getValidationState(validationContext)"
                  :options="snowOption"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <b-card-title>
          Imágenes del servicio
        </b-card-title>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Imagen principal"
              rules="required"
            >
              <b-form-group
                label="Imagen principal"
                label-for="product_image_main"
              >
                <b-form-file
                  id="product_image_main"
                  v-model="productObject.imageMain"
                  :state="getValidationState(validationContext)"
                  placeholder="Subir imagen ( 1900x420px )"
                  drop-placeholder="Drop file here..."
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Foto miniatura"
              label-for="product_images_list"
            >
              <b-form-file
                id="product_images_list"
                v-model="productObject.imageThumbnail"
                placeholder="Subir imagen ( 600x600px )"
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!-- Form Actions -->
      <div class="d-flex mb-3">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
        >
          Guardar
        </b-button>
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="$router.push({ name: 'services'})"
        >
          Cancelar
        </b-button>
      </div>

    </b-form>
  </validation-observer>
<!--  </b-card>-->
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BCardTitle, BFormInvalidFeedback, BFormFile,
} from 'bootstrap-vue'
import {
  required, alphaNum, email, numeric, digits,
} from '@validations'
import { quillEditor } from 'vue-quill-editor'
import { onUnmounted, ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import serviceStoreModule from '@/views/dramox/services/serviceStoreModule'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    quillEditor,
    BCardTitle,
    BFormInvalidFeedback,
    vSelect,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      numeric,
      digits,
      snowOption: {
        theme: 'snow',
      },
    }
  },
  setup(props, { emit }) {
    const APP_STORE_MODULE_NAME = 'app-services'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, serviceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const blankProductData = {
      name: '',
      icon: '',
      category: '',
      pricePEN: '',
      priceUSD: '',
      discount: '',
      status: '',
      descriptionInQuotation: '',
      descriptionWeb: '',
      imageMain: null,
      imageThumbnail: null,
      blocks: [
        {
          title: '',
          image: null,
          content: '',
          steps: [
            {
              icon: '',
              title: '',
              content: '',
            },
            {
              icon: '',
              title: '',
              content: '',
            },
          ],
        },
        {
          title: '',
          image: null,
          content: '',
          steps: [
            {
              icon: '',
              title: '',
              content: '',
            },
            {
              icon: '',
              title: '',
              content: '',
            },
          ],
        },
      ],
    }
    const categoryListOptions = ref([])
    const exchangeRateValue = ref(0)
    const productObject = ref(JSON.parse(JSON.stringify(blankProductData)))

    const resetProductData = () => {
      productObject.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const statusesList = [
      { id: 1, name: 'Público' },
      { id: 2, name: 'Privado' },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProductData)

    const onSubmit = () => {
      const formData = new FormData()
      formData.append('name', productObject.value.name)
      formData.append('icon', productObject.value.icon)
      formData.append('category', productObject.value.category)
      formData.append('pricePEN', productObject.value.pricePEN)
      formData.append('discount', productObject.value.discount)
      formData.append('status', productObject.value.status)
      formData.append('descriptionInQuotation', productObject.value.descriptionInQuotation)
      formData.append('descriptionWeb', productObject.value.descriptionWeb)
      formData.append('imageMain', productObject.value.imageMain)
      formData.append('imageThumbnail', productObject.value.imageThumbnail)
      formData.append('imageBlock_1', productObject.value.blocks[0].image)
      formData.append('imageBlock_2', productObject.value.blocks[1].image)
      productObject.value.blocks.forEach((item, index) => {
        formData.append(`blocks[${index}]`, JSON.stringify(item))
      })
      store.dispatch('app-services/addService', { body: formData })
        .then(() => {
          router.push({ name: 'services' })
          showNotification({ title: 'Servicio creado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    store
      .dispatch('app-services/fetchServiceCategoryList')
      .then(response => {
        const { results } = response.data.payload
        categoryListOptions.value = results
      })
      .catch(e => {
        console.log(e, 'error service category list')
      })

    store
      .dispatch('app-services/fetchExchangeRate')
      .then(response => {
        const { exchange_rate: exchangeRate } = response.data.payload.results
        exchangeRateValue.value = exchangeRate
      })
      .catch(e => {
        console.log(e, 'error fetch excnage rate')
      })

    const pricePENListen = val => {
      productObject.value.priceUSD = (+val.target.value / exchangeRateValue.value).toFixed(6)
    }

    const priceUSDListen = val => {
      productObject.value.pricePEN = (+val.target.value * exchangeRateValue.value).toFixed(6)
    }

    return {
      productObject,

      statusesList,
      categoryListOptions,
      // businessData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,

      pricePENListen,
      priceUSDListen,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
